import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn, MDBContainer } from "mdbreact";

const AboutUs = () => {
    return (
        <>
            <MDBContainer className="mt-5">
                <MDBRow>
                    <MDBCol lg="5">
                        <MDBView className="rounded mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src={`${process.env.PUBLIC_URL}/img/about/about_01.jpg`}
                                alt=""
                            />
                            <a href="#!">
                                <MDBMask overlay="white-slight" />
                            </a>
                        </MDBView>
                    </MDBCol>
                    <MDBCol lg="7">
                        <h3 className="h3 font-weight-bold">
                            Hakkımızda
                        </h3>
                        <h4 className="font-weight-bold mb-3 p-0">
                            <strong>İşinizi Bir Sonraki Aşamaya Birlikte Taşıyalım.</strong>
                        </h4>
                        <p>
                            YIGISOFT web ve mobil tabanlı yazılım çözümleri sunan, 
                            kaliteli teknik personeliyle sizlere E-Dönüşümde rehberlik eden bir teknoloji firmasıdır. 
                            Firmanızın hedeflerine uygun kaliteli projeler ve çözümler üreterek iş dünyasında daha güçlü durmanıza yardımcı olur.
                         </p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <span  id="customers"></span>
            <hr className="my-5"/>
        </>
    );
}

export default AboutUs;