import React, { useState } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBAlert, MDBIcon, MDBBtn, MDBInput, MDBContainer } from "mdbreact";

const Contact = () => {

    const [contact, setContact] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
    const [mailResult, setMailResult] = useState(null);

    const handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        setContact({ ...contact, [name]: value });
    }

    const validate = () => {
        if ((!contact.name || !contact.name.trim())) {
            setContact({ ...contact, name: "" });
            return false;
        }
        if ((!contact.email || !contact.email.trim())) {
            setContact({ ...contact, email: "" });
            return false;
        }
        if ((!contact.subject || !contact.subject.trim())) {
            setContact({ ...contact, subject: "" });
            return false;
        }
        if ((!contact.message || !contact.message.trim())) {
            setContact({ ...contact, message: "" });
            return false;
        }
        return true;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        console.log("object")
        e.target.className += " was-validated";
        if (!validate()) {
            return;
        }

        await fetch("/ContactApi", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.     
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contact) // body data type must match "Content-Type" header
        }).then(result => {
            showMailResult(result);
        });
    }

    const showMailResult = async (result) => {
        var data = await result.json();
        if (result.ok) {
            setMailResult(<MDBAlert color="success" className="mb-5" >
                {data.message}
            </MDBAlert>)
        } else {
            setMailResult(<MDBAlert color="danger" className="mb-5" >
                {data.message}
            </MDBAlert>)
        }
        setTimeout(() => {
            setMailResult(null);
        }, 2000);
    }

    return (
        <MDBContainer className="my-5 ">
            <h2 className="h1-responsive font-weight-bold text-center my-5">
                Görüşleriniz bizim için değerli.
      </h2>
            <p className="text-center w-responsive mx-auto pb-5">
                YIGISOFT web ve mobil tabanlı yazılım çözümleri sunan, kaliteli teknik personeliyle sizlere
                E-Dönüşümde rehberlik eden bir teknoloji firmasıdır. Firmanızın hedeflerine uygun kaliteli projeler ve çözümler üreterek iş dünyasında daha güçlü durmanıza yardımcı olur.
      </p>
            
            <MDBRow>
                <MDBCol lg="5" className="lg-0 mb-4">
                    <MDBCard>

                        <MDBCardBody>
                            <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                                <div className="form-header blue accent-1">
                                    <h5 className="mt-2">
                                        <MDBIcon icon="envelope mr-2" /> Bizimle iletişime geçin.
                </h5>
                                </div>
                                
                                {mailResult || ""}
                                <div className="md-form">
                                    <MDBInput
                                        icon="user"
                                        name="name"
                                        label="İsminiz"
                                        iconClass="grey-text"
                                        type="text"
                                        id="form-name"
                                        required
                                        value={contact.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="md-form">
                                    <MDBInput
                                        icon="envelope"
                                        name="email"
                                        label="E-posta adresiniz"
                                        iconClass="grey-text"
                                        type="text"
                                        id="form-email"
                                        required
                                        value={contact.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="md-form">
                                    <MDBInput
                                        icon="tag"
                                        name="subject"
                                        label="Konu"
                                        iconClass="grey-text"
                                        type="text"
                                        id="form-subject"
                                        required
                                        value={contact.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="md-form">
                                    <MDBInput
                                        icon="pencil-alt"
                                        name="message"
                                        label="İçerik"
                                        iconClass="grey-text"
                                        type="textarea"
                                        id="form-text"
                                        required
                                        value={contact.message}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="text-right">
                                    <MDBBtn type="submit" color="light-blue">Gönder</MDBBtn>
                                </div>
                            </form>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="7" className="mb-4">
                    <div
                        style={{ minHeight: 350 }}
                        id="map-container"
                        className="rounded z-depth-1-half map-container h-100"
                    >
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3079.8628847231685!2d30.026026015628304!3d39.472426220447986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c94fc7684060f9%3A0x1a6c56134296d665!2sK%C3%BCtahya%20Teknokent!5e0!3m2!1str!2str!4v1612536268517!5m2!1str!2str"
                            title="This is a unique title"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                        />
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Contact;