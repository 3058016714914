import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBContainer } from "mdbreact";
import React from "react";
import { a, BrowserRouter as Router } from "react-router-dom";
import MultipleCarousel from "../MultipleCarousel/MultipleCarousel";

const items = [
    <MDBCard className="m-2">
        <MDBCardImage className="img-fluid" src={`${process.env.PUBLIC_URL}/img/products/4DPersonelTakip.png`} />
        <MDBCardBody>
            <MDBCardTitle>4D Personel Takip</MDBCardTitle>
            <MDBCardText className="text-left crop">
                Kamuda çalışan 657 sayılı kanunun 4. maddesinin D bendine tabi personeller için personel bilgilerinin tanımlanabildiği,
                günlük puantaj kayıtlarının yapılabildiği ikramiye ve sendikal hakların tanımlanabildiği ve bu bilgilere göre aylık bordrolama hizmetinin alındığı bir web platformudur.
            </MDBCardText>
            <a href="/products/4d-personel-takip" className="text-muted small">detaylı bilgi için..</a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2">
        <MDBCardImage className="img-fluid" src={`${process.env.PUBLIC_URL}/img/products/Depo101.png`} />
        <MDBCardBody>
            <MDBCardTitle>Depo 101</MDBCardTitle>
            <MDBCardText className="text-left crop">
                Depolarınızda bulunan malzemelerinizin takibini yapabildiğiniz detaylı stok ve depo raporları alabildiğiniz bir web uygulamasıdır.
            </MDBCardText>
            <a href="/products/depo-101" className="text-muted small">detaylı bilgi için..</a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2">
        <MDBCardImage className="img-fluid" src={`${process.env.PUBLIC_URL}/img/products/EnvanterTakip.png`} />
        <MDBCardBody>
            <MDBCardTitle>Envanter Takip</MDBCardTitle>
            <MDBCardText className="text-left crop">
                Firmanızda bulunan demirbaşların takibini yapabileceğiniz. Demirbaşlara sorumlu personeller atayabileceğiniz bir web uygulamasıdır.
            </MDBCardText>
            <a href="/products/envanter-takip" className="text-muted small">detaylı bilgi için..</a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2">
        <MDBCardImage className="img-fluid" src={`${process.env.PUBLIC_URL}/img/products/YillikIzin.png`} />
        <MDBCardBody>
            <MDBCardTitle>Yıllık İzin Takip</MDBCardTitle>
            <MDBCardText className="text-left crop">
                Firmanızda çalışan personellerinizi yıllık izinlerini düzenleyebildiğiniz,
                bölüm içi yada kritik pozisyonlarda bulunan çalışanlarınızın aldıkları izinlerin çakışmalarını görüp müdahale edebildiğiniz bir web uygulamasıdır.
            </MDBCardText>
            <a href="/products/yillik-izin-takip" className="text-muted small">detaylı bilgi için..</a>
        </MDBCardBody>
    </MDBCard>
];

const Products = () => {
    return (
        <section className="customers text-center">
            <h3 className="h3 font-weight-bold mb-5">
                Ürünlerimiz
            </h3>
            <Router>
                <MultipleCarousel items={items} />
            </Router>
            <hr className="my-5" />
        </section>
    )
}

export default Products
