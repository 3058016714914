import React from "react";
import { MDBRow, MDBCol, MDBMask, MDBView, MDBContainer, MDBIcon } from "mdbreact";
import Timeline from "../Timeline/Timeline";


const Content = ({ content }) => {
    if (content.contentType === "left") {
        return (
            <MDBContainer className="mt-5">
                <MDBRow>
                    <MDBCol lg="5">
                        <MDBView className="rounded mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src={content.src || ""}
                                alt={content.title || ""}
                                style={{ maxHeight: 300 }}
                            />
                            <MDBMask overlay="white-slight" />
                        </MDBView>
                    </MDBCol>
                    <MDBCol lg="7" className="d-flex flex-column justify-content-center">
                        <h3 className="h3 font-weight-bold">
                            {content.title || ""}
                        </h3>
                        <h4 className="font-weight-bold mb-3 p-0">
                            <strong>{content.subTitle || ""}</strong>
                        </h4>
                        <p dangerouslySetInnerHTML={{ __html: content.description || "" }}>
                        </p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    } else if (content.contentType === "right") {
        return (
            <MDBContainer className="mt-5">
                <MDBRow>
                    <MDBCol lg="7" className="d-flex flex-column justify-content-center">
                        <h3 className="h3 font-weight-bold">
                            {content.title || ""}
                        </h3>
                        <h4 className="font-weight-bold mb-3 p-0">
                            <strong>{content.subTitle || ""}</strong>
                        </h4>
                        <p dangerouslySetInnerHTML={{ __html: content.description || "" }}></p>
                    </MDBCol>
                    <MDBCol lg="5" >
                        <MDBView className="rounded mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src={content.src || ""}
                                alt={content.title || ""}
                                style={{ maxHeight: 300 }}
                            />
                            <MDBMask overlay="white-slight" />
                        </MDBView>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    } else if (content.contentType === "only_photo") {
        return (
            <MDBContainer className="mt-5">
                <MDBRow>
                    <MDBCol >
                        <MDBView className="rounded mb-lg-0 mb-4 only-photo" hover waves>
                            <img
                                className="img-fluid"
                                src={content.src || ""}
                                alt={content.title || ""}
                                style={{ backgroundPosition: "center", backgroundSize: "cover" }}
                            />
                            <MDBMask overlay="white-slight" />
                        </MDBView>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    } else if (content.contentType === "timeline") {
        return <MDBContainer>
            <Timeline content={content.items}></Timeline>
        </MDBContainer>
    }
    else {
        return <></>;
    }
}

export default Content;