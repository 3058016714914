import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
    "mdbreact";

const datas = [
    {
        id: 1,
        img: "slide_1.jpg",
        title: "YIGISOFT",
        subTitle: "Dijital Dünyada Size Özel Çözümler"
    },
    {
        id: 2,
        img: "slide_2.jpg",
        title: "YIGISOFT",
        subTitle: "Teknoloji Düşündüğünüzden Daha Yakın"
    },
    {
        id: 3,
        img: "slide_3.jpg",
        title: "YIGISOFT",
        subTitle: "Dijital Dünyada Size Özel Çözümler"
    }
]

const mapItem = (datas) => {
    return datas.map((data) => {
        return <MDBCarouselItem itemId={data.id} key={data.id}>
            <MDBView>
                <img
                    className="d-block w-100 h-100"
                    src={`${process.env.PUBLIC_URL}/img/carousel/${data.img}`}
                    alt={data.subTitle}
                    style={{ objectFit: "cover"}}
                />
                <MDBMask overlay="black-light" className="flex-center">
                </MDBMask>
            </MDBView>
            <MDBCarouselCaption className="flex-column">               
                <p className="h1 bg-text">{data.subTitle}</p>
            </MDBCarouselCaption>
        </MDBCarouselItem>;
    })
}

const Carousel = () => {
    return (
        <>
        <MDBCarousel
            activeItem={1}
            length={3}
            showControls={true}
            showIndicators={true}
            className="z-depth-1"
        >
            <MDBCarouselInner>
                {mapItem(datas)}
            </MDBCarouselInner>
        </MDBCarousel>
        <span id="about-us"></span>
        </>
    );
}

export default Carousel;