import React from "react";
import "./timeline.css"

const Timeline = ({ content }) => {
    
    var res = content.map((data, key) => {
        return <div className="titem" key={key}>
            <h4 className="font-weight-bold">{data.title}</h4>
            <span dangerouslySetInnerHTML={{ __html: data.description || "" }}></span>
        </div>
    });
    
    return <div className="timeline">
        {res};

    </div>;
}

export default Timeline;