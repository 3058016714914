import { MDBContainer } from 'mdbreact';
import React from 'react'

export const HowItWorks = () => {
    return (
        <>
            <MDBContainer>
                <h2 className="my-5 h3 text-center font-weight-bold">Nasıl Çalışıyoruz</h2>
                <div className="row features-small my-5 wow fadeIn flex-center">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Planlama</h6>
                                <p className="grey-text">Size en uygun seçeneği belirler, ihtiyaçlarınızı kapsamlı bir şekilde değerlendirir, size uygun yol haritasını çıkartırız.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Tasarım</h6>
                                <p className="grey-text">Web tasarım ve mobil uygulama alanında uzmanlaşmış ekibimizle, ihtiyacınıza uygun ara yüz çalışmalarını gerçekleştiririz.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Yazılım</h6>
                                <p className="grey-text">Proje konseptinin uygulanabilirliği çerçevesinde ve çıkarılan yol haritasına uygun bir şekilde yazılım çalışmalarını başlatırız.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 flex-center">
                        <img src={`${process.env.PUBLIC_URL}/img/howitworks/2.png`} alt="MDB Magazine Template displayed on iPhone" className="z-depth-0 img-fluid" />
                    </div>
                    <div className="col-md-4 mt-2">
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Test</h6>
                                <p className="grey-text">Ürünü, ilk sürümünden itibaren test aşamasına tabi tutarak eksiklerini tespit ederiz ve gerekli düzeltmeleri planlarız.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Geliştirme</h6>
                                <p className="grey-text">Test aşamasında belirlenen revizyonları ele alırız. Revizyonların ardından tekrar test ederek teslime hazır hale getiririz.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="fas fa-check-circle fa-2x indigo-text"></i>
                            </div>
                            <div className="col-10">
                                <h6 className="feature-title">Sunum</h6>
                                <p className="grey-text">Müşteriye ürünün tanıtımını yaparız. Ürün teslim edildikten sonra da geliştirmeler konusunda iş ortaklığını sürdürürüz.</p>
                                <div style={{ height: 15 }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </MDBContainer>
            <span id="products"></span>
            <hr className="my-5" />
        </>
    );
}
