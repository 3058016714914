import React, { Component } from "react";
import "./index.css";
import { Navbar } from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";
import { Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Carousel from "./components/Carousel/Carousel";
import Footer from "./components/Footer";
import Product from "./components/Product/Product";

class App extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Carousel />
        <Switch>
          <Route path="/products/:name" exact>
            <Product />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </>
    );
  }
}

export default App;
