import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBContainer } from "mdbreact";
import React from "react";
import MultipleCarousel from "../MultipleCarousel/MultipleCarousel";

const items = [
    <MDBCard className="m-2" >
        <MDBCardImage className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/customers/dpu_logo.png`} />
        <MDBCardBody>
            <a href="https://dpu.edu.tr">
                <MDBCardTitle>Kütahya Dumlupınar Üniversitesi</MDBCardTitle>
            </a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2" >
        <MDBCardImage className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/customers/bolis_plastik_logo.png`} />
        <MDBCardBody>
            <a href="http://www.bolisplastik.com/">
                <MDBCardTitle>Boliş Plastik</MDBCardTitle>
            </a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2" >
        <MDBCardImage className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/customers/kuartek_logo.png`} />
        <MDBCardBody>
            <a href="http://kuartek.com/">
                <MDBCardTitle>Kuartek Tasarım</MDBCardTitle>
            </a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2" >
        <MDBCardImage className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/customers/ramosis_logo_1.png`} />
        <MDBCardBody>
            <a href="http://ramosis.com/">
                <MDBCardTitle>Ramosis Teknoloji & Tasarım</MDBCardTitle>
            </a>
        </MDBCardBody>
    </MDBCard>,
    <MDBCard className="m-2" >
        <MDBCardImage className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/customers/fotoniksoft_logo.png`} />
        <MDBCardBody>
            <a href="http://fotoniksoft.com/">
                <MDBCardTitle>FotonikSoft</MDBCardTitle>
            </a>
        </MDBCardBody>
    </MDBCard>  
];

const Customers = () => {
    return (
        <section className="customers text-center">
            <h3 className="h3 font-weight-bold mb-5">
                Referanslarımız
            </h3>
            <MultipleCarousel items={items} />
            <span id="how-it-works"></span>
            <hr className="my-5" />
        </section>
    );
}

export default Customers;