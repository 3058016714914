import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact'
import React from 'react'
import "./price-card.css";

const PriceCard = ({ content }) => {
  return (
    <MDBCard pricing className="">
      <div className={`price header white-text rounded-top ${content.bgColor}`}>
        <h2 className="number">{content.price}</h2>
        <div className="version">
          <h5 className="mb-0">{content.title}</h5>
        </div>
      </div>
      <MDBCardBody className="striped mb-1">
        <ul>
          {content.features.map((feature, key) => {
            return <li key={key}>
              <p className="mt-2 text-left">
                <MDBIcon icon="check" className="green-text pr-2" />
                <span dangerouslySetInnerHTML={{ __html: feature || "" }}></span>
              </p>
            </li>
          })}
        </ul>
      </MDBCardBody>
    </MDBCard>
  )
}

export default PriceCard
