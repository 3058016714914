import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from 'mdbreact';
import React from 'react'

const Footer = () => {
    return (
        <MDBFooter className="font-small pt-4">
            <MDBContainer  className="text-center">
                <MDBRow>
                    <MDBCol md="3">
                        <img
                            src={`${process.env.PUBLIC_URL}/img/logos/logo-2.png`}
                            alt="YigiSoft"
                            style={{ width: 250 }}
                        />
                    </MDBCol>
                    <MDBCol md="3">
                        <h5 className="title">Sosyal Ağlarda Biz</h5>
                        <hr className="my-4"></hr>
                        <div className="pb-4">

                            <a href="https://www.linkedin.com/" target="_blank">
                                <MDBIcon fab icon="linkedin" className="mr-5 fa-2x" />
                            </a>

                            <a href="https://instagram.com/" target="_blank">
                                <MDBIcon fab icon="instagram" className="mr-5 fa-2x" />
                            </a>

                            <a href="https://youtube.com/" target="_blank">
                                <MDBIcon fab icon="youtube" className=" fa-2x" />
                            </a>
                        </div>
                    </MDBCol>
                    <MDBCol md="3">
                        <h5 className="title">İletişim</h5>
                        <hr className="my-4"></hr>
                        <div className="pb-4">
                            <a href="mailto:xyz@abc.com">
                                <MDBIcon far icon="envelope" className="mr-1" /> info@yigisoft.com
                            </a>
                        </div>
                    </MDBCol>
                    <MDBCol md="3">
                        <h5 className="title ">Adres</h5>
                        <hr className="my-4"></hr>
                        <div className="pb-4">
                            <p>
                            Kütahya Tasarım Teknokent Ofis,<br/>
                            Eskişehir Karayolu 5. Km <br/>
                            2.Organize Sanayi Bölgesi <br/>
                            Tasarım Teknokent Merkez / Kütahya
                            </p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; {new Date().getFullYear()} Copyright: <a href="https://www.yigisoft.com"> yigisoft.com </a>
                </MDBContainer>
            </div>
        </MDBFooter>
    )
}

export default Footer;
