import { MDBCol, MDBContainer, MDBRow, } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { useParams,useHistory } from 'react-router-dom'
import Content from '../Content/Content';
import PriceCard from '../PriceCard/PriceCard';
import "./product.css"
import personel from "./4dpersonel.json";
import depo from "./depo101.json";
import envanter from './envanter.json';
import yillikIzin from './yillik_izin.json';

const Product = () => {

    const [paymentPlans, setPaymentPlans] = useState([]);
    const [datas, setDatas] = useState({});

    var params = useParams("name");
    var history = useHistory();
    useEffect(() => {
        var datas = {};
        switch (params.name) {
            case "4d-personel-takip":
                datas = personel;
                break;
            case "depo-101":
                datas = depo;
                break;            
            case "envanter-takip":
                datas = envanter;
                break;
            case "yillik-izin-takip":
                datas = yillikIzin;
                break;
            default:
                history.push("/");
                break;
        }

        setDatas(datas);
        setPaymentPlans(datas?.yearly);
    }, []);

    const switchPaymentPlan = (btn) => {
        if (btn.target.id == "yearly") {
            document.getElementById("standard").classList.remove("active");
            if (!btn.target.classList.contains("active"))
                btn.target.classList.add("active");
            setPaymentPlans(datas?.yearly);
        }
        else if (btn.target.id == "standard") {
            document.getElementById("yearly").classList.remove("active");
            if (!btn.target.classList.contains("active"))
                btn.target.classList.add("active");
            setPaymentPlans(datas?.standart);
        }
    }

    return (
        <>
            {datas&& datas.program && datas.program.map((data, key) => {
                return <div key={key} >
                    <Content content={data} />
                    <hr className="my-5" />
                </div>;
            })}
            {paymentPlans.length>0?<MDBContainer>
                <div className="text-center mb-5">
                    <h2 className="mb-3 h3 font-weight-bold">Uygun Ödeme Planlarımız</h2>
                    <p className="mb-5">Personel yönetim süreçlerinizi hızlı ve kolay bir şekilde organize edebiliriniz.</p>

                    <div className="btn-group btn-group-toggle rounded-pill btn-switch" data-toggle="buttons">
                        <label className="btn active" id="yearly" onClick={(e) => switchPaymentPlan(e)} >
                            Yıllık
                        </label>
                        <label className="btn" id="standard" onClick={(e) => switchPaymentPlan(e)}>
                            Standart
                        </label>
                    </div>
                </div>

                <MDBRow>
                    {paymentPlans.map((plan, key) => {
                        return <MDBCol md="6" lg="3" className="mb-5" key={key}>
                            <PriceCard content={plan} />
                        </MDBCol>
                    })}
                </MDBRow>
            </MDBContainer>:""}
            
        </>
    )
}

export default Product
