import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory, useParams } from 'react-router-dom';
import {
    MDBContainer,
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBIcon
} from "mdbreact";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState("/");

    const history = useHistory();

    useEffect(() => {
        setActive(history.location.pathname)
    }, [])

    const activeItem = (pathname) => {
        setIsOpen(window.innerWidth < 768 && !isOpen);
        setActive(pathname);
    }

    return (
        <Router>
            <MDBNavbar expand="md" dark transparent scrolling fixed="top" className="p-0" >
                <MDBContainer>
                    <MDBNavbarBrand>
                        <a className="nav-link" href="/">
                            <img
                                src={`${process.env.PUBLIC_URL}/img/logos/logo-white.png`}
                                alt="YigiSoft"
                                style={{ height: 53 }}
                            />
                        </a>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
                    <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
                        <MDBNavbarNav left>
                            <MDBNavItem active={active === "/"} onClick={() => activeItem("/")}>
                                <a className="nav-link" href="/">Ana Sayfa</a>
                            </MDBNavItem>
                            <MDBNavItem active={active === "/#about-us"} onClick={() => activeItem("/#about-us")}>
                                <a href="/#about-us" className="nav-link">Hakkımızda</a>
                            </MDBNavItem>
                            <MDBNavItem active={active === "/#customers"} onClick={() => activeItem("/#customers")}>
                                <a href="/#customers" className="nav-link">Referanslar</a>
                            </MDBNavItem>
                            <MDBNavItem active={active === "/#how-it-works"} onClick={() => activeItem("/#how-it-works")}>
                                <a href="/#how-it-works" className="nav-link">Nasıl Çalışıyoruz</a>
                            </MDBNavItem>
                            <MDBNavItem active={active === "/#products"} onClick={() => activeItem("/#products")}>
                                <a href="/#products" className="nav-link">Ürünlerimiz</a>
                            </MDBNavItem>
                            <MDBNavItem active={active === "/contact"} onClick={() => activeItem("/contact")}>
                                <a className="nav-link" href="/contact">İletişim</a>
                            </MDBNavItem>
                        </MDBNavbarNav>
                        <MDBNavbarNav right>                            
                            <MDBNavItem>
                                <a className="nav-link waves-effect waves-light" target="blank" href="https://www.instagram.com/yigisoft/">
                                    <MDBIcon fab icon="instagram" />
                                </a>
                            </MDBNavItem>
                            <MDBNavItem>
                                <a className="nav-link waves-effect waves-light" target="blank" href="https://www.linkedin.com/company/yigisoft-teknoloji">
                                    <MDBIcon fab icon="linkedin" />
                                </a>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </Router>
    )
}
