import React, { Component } from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import AboutUs from "../AboutUs/AboutUs";
import Customers from "../Customers/Customers";
import { HowItWorks } from "../HowItWorks/HowItWorks";
import Products from "../Products/Products";

class Home extends Component {
    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    <AboutUs />
                    <Customers />
                    <HowItWorks />
                    <Products />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default Home;
